import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FlexWrapper from "../components/FlexWrapper"
import { H3 } from "../components/Headline"

import "./about.css"

const SecondPage = () => (
  <Layout>
    <SEO title="Om" />
    <FlexWrapper className="FlexWrapper-about">
      <div>
        <H3>Kommersiell hyresjuridik - Lokalförsörjning</H3>
        <p style={{ fontSize: "0.8em" }}>
          Under större delen av mitt yrkesliv har jag arbetat med kommersiell
          hyresjuridik och fastighetsjuridik. Som jurist och konsult arbetar jag
          för åtskilliga av landets statliga myndigheter och rikstäckande
          företag i lokalförsörjningsuppdrag med strategiskt och operativt
          ansvar för hyresförhandlingar, avtalsfrågor och övrig hyresjuridisk
          och fastighetsjuridisk rådgivning.
        </p>
      </div>
      <div>
        <H3>
          <span>Upphovsrätt - Musikjuridik</span>
        </H3>
        <p style={{ fontSize: "0.8em" }}>
          Med mångårig erfarenhet från arbete med kommersiella avtal, komplexa
          och omfattande förhandlings- och avtalsupplägg arbetar jag även med
          juridisk rådgivning och kommersiellt stöd inom musikjuridik och andra
          upphovsrättsliga områden för musiker, artister, författare och andra
          konstskapare.
        </p>
      </div>
    </FlexWrapper>
    <div className="About-footer">
      <h6>Ulf Lindegård - UL JURIDIK</h6>
    </div>
  </Layout>
)

export default SecondPage
